#service-main-panel {
    background: url(../../images/about/about-right-patterns.png) no-repeat;
    background-position: right;
    .service-padding {
        padding: 0 50px;
        @include lg {
            padding: 0 20px;
        }
        .service-main-content {
            // border-radius: 15px;
            // margin-top: -50px;
            // background-color: rgba($color: #fff, $alpha: 0.42);
            // box-shadow: 0 -10px 10px rgb(0 0 0 / 13%);
            .our-service-section {
                .heading-section {
                    padding: 30px 60px;
                    @include sm {
                        padding: 60px 10px 30px 10px;
                        text-align: center;
                    }
                }
                .service-cards-section-bg-image {
                    position: relative;
                    background: url(../../images/service/service-card-section-bg-img.png) no-repeat;
                    background-size: cover;
                    height: 100%;
                    width: 100%;
                    padding: 20px;
                    .service-cards-section {
                        padding: 20px 0;
                        @include lg {
                            padding: 15px 0;
                        }
                        &.flex-content {
                            @include laptop-min {
                                justify-content: center !important;
                            }
                        }
                        .service-card {
                            margin: 10px 0;
                            padding: 10px 20px;
                            height: 355px;
                            width: 100%;
                            max-width: 530px;
                            background-color: $accent-white-color;
                            border-radius: 8px;
                            box-shadow: 10px -10px rgba($color: $accent-white-color, $alpha: 0.42);
                            margin: 0 auto;
                            @include lg {
                                flex-wrap: wrap;
                                height: 400px;
                                padding: 20px 10px;
                            }
                            .left-text-content {
                                background: url(../../images/service/left-content-bg-img.png) no-repeat;
                                background-size: cover;
                                padding: 0 20px 0 0;
                                text-align: justify;
                                @include lg {
                                    padding: 0;
                                    text-align: center;
                                }
                                span {
                                    @include md {
                                        font-size: $font12 !important;
                                        line-height: $lheight16 !important;
                                    }
                                }
                                &.order-left-resp {
                                    @include lg {
                                        order: 2;
                                    }
                                }
                            }
                            .right-image-content {
                                @include lg {
                                    margin: auto;
                                }
                                .image-wrapper {
                                    position: relative;
                                    cursor: pointer;
                                    &:hover {
                                        &::before {
                                            position: absolute;
                                            content: "";
                                            background: url("../../images/common/image-red-hover.png") no-repeat;
                                            background-size: 100% 100%;
                                            height: 100%;
                                            max-height: 100%;
                                            max-width: 100%;
                                            width: 230px;
                                            left: 0;
                                            right: 0;
                                            margin: auto;
                                            top: 0;
                                            @include lg {
                                                width: 114px;
                                            }
                                        }
                                    }
                                    .img-responsive {
                                        height: 200px;
                                        max-height: 100%;
                                        min-width: 170px;
                                        width: 100%;
                                        object-fit: contain;
                                        @include lg {
                                            height: 130px;
                                            width: 114px;
                                        }
                                    }
                                }
                                .title-text {
                                    cursor: pointer;
                                    span {
                                        &:hover {
                                            color: $red-primary-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .nhss-services-panel {
                .nhss-services-main-content {
                    &.container-padding {
                        padding: 100px 50px 50px 50px !important;
                        @include laptop {
                            padding: 50px 0px !important;
                        }
                    }
                    .nhss-service-content {
                        border-radius: 20px;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px -1px 20px;
                        background: $accent-white-color;
                        .service-left-content {
                            background: url(../../images/service/nhss-service-left-image.png) no-repeat;
                            background-position: top left;
                            background-size: cover;
                            padding: 40px;
                            @include lg {
                                padding: 40px;
                                text-align: center;
                            }
                            @include mobile {
                                padding: 20px;
                            }
                        }
                        .service-right-content {
                            background: url(../../images/service/nhss-service-right-image.png) no-repeat;
                            background-position: right;
                            background-size: cover;
                            padding: 0px 40px;
                            border-radius: 0 20px 20px 0;
                            box-shadow: 10px -10px 10px rgba(150, 149, 149, 0.13);
                            @include lg {
                                border-radius: 0 0 20px 20px;
                                padding: 20px 40px;
                            }
                            @include md {
                                padding: 20px;
                            }
                            .flex-content-wrapper {
                                @include lg {
                                    align-items: center !important;
                                }
                                .service-heading-section {
                                    @include lg {
                                        text-align: center !important;
                                    }
                                }
                            }
                            .view-all-btn {
                                .btn {
                                    width: 130px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .let-us-help-section {
        .let-us-help-content {
            &.container-padding {
                padding: 50px !important;
                @include laptop {
                    padding: 30px 0px !important;
                }
            }
            .left-section {
                @include lg {
                    text-align: center;
                }
                .image-wrapper {
                    img {
                        height: 450px;
                        width: 450px;
                        max-width: 100%;
                        object-fit: cover;
                        @include sm {
                            height: 350px;
                        }
                        @include mobile {
                            height: 240px;
                        }
                    }
                }
            }
            .right-section {
                background: url(../../images/service/get-online-bg.png);
                background-size: 100% 100%;
                max-width: 550px;
                margin: 0 0 0 auto;
                border-radius: 10px;
                background-color: $accent-light-gray-color;
                @include lg {
                    margin: auto;
                }
                .get-online-section {
                    padding: 100px 50px;
                    @include mobile {
                        padding: 80px 30px;
                    }
                    @include sm {
                        margin-top: 55px;
                    }
                    .get-online-box {
                        background-color: $accent-black-color;
                        height: 60px;
                        width: 320px;
                        border-radius: 5px;
                        left: 0;
                        top: -30px;
                        right: 0;
                        margin: auto;
                        max-width: 100%;
                        @include sm {
                            height: 45px;
                            width: 215px;
                            top: -23px;
                        }
                        .get-online-text {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                        }
                    }
                    .button-wrapper {
                        position: absolute;
                        left: 32%;
                        bottom: -15px;
                        @include md {
                            left: 30%;
                        }
                        @include mobile {
                            left: 26%;
                        }
                        @include mobile-min {
                            left: 24%;
                        }
                        .book-consult {
                            width: 200px;
                            height: 35px;
                            max-width: 100%;
                            position: relative;
                            border: 1px solid transparent;
                            text-align: left;
                            padding: 0 20px;
                            transition: 500ms ease-in-out;
                            @include sm {
                                width: 150px;
                                height: 30px;
                                padding: 0 5px;
                            }
                            &::after {
                                position: absolute;
                                content: "";
                                background: url("../../images/svg-icon/button-right-arrow.svg") no-repeat;
                                background-size: 100% 100%;
                                height: 27px;
                                width: 28px;
                                right: 8px;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                @include sm {
                                    width: 23px;
                                    height: 20px;
                                }
                            }
                            &:hover {
                                &::after {
                                    background: url("../../images/svg-icon/button-right-red-arrow.svg") no-repeat;
                                    background-size: 100% 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .lets-connect-section {
        .lets-connect-content {
            background: url(../../images/service/lets-connect-bg.png) no-repeat;
            background-position: center;
            background-size: cover;
            padding: 40px;
            @include md {
                text-align: center;
                padding: 20px;
            }
            @include laptop {
                padding: 30px 50px !important;
            }
            @include lg {
                padding: 30px !important;
            }
            @include mobile {
                padding: 10px !important;
            }
            &::before {
                content: "";
                position: absolute;
                width: 90%;
                height: 10px;
                background-color: $accent-dark-gray-color;
                right: 0;
                top: -10px;
            }
            &::after {
                content: "";
                position: absolute;
                width: 90%;
                height: 10px;
                background-color: $accent-dark-gray-color;
                left: 0;
                bottom: -10px;
            }
            .right-image-section {
                top: -100px;
                img {
                    height: 300px;
                    width: 300px;
                }
                @include md {
                    display: none;
                }
            }
            .button-wrapper {
                .lets-connect-btn {
                    width: 160px !important;
                }
            }
        }
    }
}
