#new-contact-wrapper {
    background: url("../../images/newcontact/contact-bg.png") no-repeat;
    background-size: cover;
    background-position: right top;
    .contect-content {
        &.container-padding {
            padding: 100px !important;
            @include laptop {
                padding: 50px !important;
            }
            @include lg {
                padding: 30px !important;
            }
            @include mobile {
                padding: 15px !important;
            }
        }
        .left-section {
            .know-us-better-section {
                @include sm {
                    flex-direction: column;
                }
                .contact-details-block {
                    position: relative;
                    padding: 35px 0 35px 100px;
                    min-height: 105px;
                    display: flex;
                    align-items: center;
                    margin: 0 40px;
                    @include md {
                        padding: 23px 0 35px 100px;
                        margin: 0 20px;
                    }
                    @include sm {
                        padding: 10px 0 20px 80px;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        width: 60px;
                        height: 60px;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                    &.address-block {
                        &:before {
                            background: url("../../images/newcontact/location-new-contact.svg") no-repeat;
                            background-size: 60px;
                        }
                    }
                    &.mail-block {
                        &:before {
                            background: url("../../images/newcontact/mail-new-contact.svg") no-repeat;
                            background-size: 60px;
                        }
                    }
                    &.phone-block {
                        &:before {
                            background: url("../../images/newcontact/phone-new-contact.svg") no-repeat;
                            background-size: 60px;
                        }
                    }
                    .address-text {
                        .left-section-text {
                            font-family: "Segeo Ui-semi-bold" !important;
                            color: $accent-dark-gray-color;
                        }
                    }
                }
            }
            .contact-street-map {
                .street-map-block {
                    background-size: cover;
                    background-position: center center;
                    height: 500px;
                }
            }
        }
        .right-section {
            position: relative;
            @include laptop {
                margin-left: 0;
            }
            @include xl {
                padding-bottom: 50px;
            }
            .contact-section {
                padding: 100px 70px;
                max-width: 700px;
                width: 100%;
                margin: auto;
                background-color: $accent-white-color;
                border-radius: 15px;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                @include lg {
                    padding: 80px 30px;
                }
                @include sm {
                    padding: 80px 20px;
                }
                .contact-box {
                    position: absolute;
                    top: -30px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 165px;
                    height: 60px;
                    background-color: $accent-dark-gray-color;
                    border-radius: 8px;
                    @include sm {
                        width: 130px;
                        height: 53px;
                    }
                    .contact-text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        color: $accent-white-color;
                        font-family: "Segeo Ui-bold" !important;
                    }
                }
                .input-block {
                    margin-bottom: 30px;
                    @include sm {
                        margin-top: 20px;
                    }
                }
                .button-wrapper {
                    position: absolute;
                    bottom: -17px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                    @include xl {
                        bottom: 33px;
                    }
                    .submit-now {
                        background-color: $red-primary-color;
                        position: relative;
                        text-align: left;
                        padding: 0 20px;
                        border: 1px solid transparent;
                        text-align: left;
                        height: 35px;
                        transition: 500ms ease-in-out;
                        @include md {
                            width: 150px;
                        }
                        &:disabled {
                            opacity: 1;
                            cursor: not-allowed;
                            &:hover {
                                background-color: $red-primary-color !important;
                                color: $accent-white-color !important;
                                &:after {
                                    background: url("../../images/contact/submit-now-right-arrow.png") no-repeat;
                                }
                            }
                        }
                        &::after {
                            position: absolute;
                            content: "";
                            background: url("../../images/contact/submit-now-right-arrow.png") no-repeat;
                            height: 27px;
                            width: 28px;
                            right: 15px;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            background-position: center !important;
                        }
                        &:hover {
                            &::after {
                                background: url("../../images/svg-icon/button-right-red-arrow.svg") no-repeat;
                            }
                        }
                    }
                }
            }
        }
    }
}
