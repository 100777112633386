.admin-table {
  .cell-name {
    width: 13%;
  }
  .cell-email {
    width: 13%;
  }
  .cell-phone {
    width: 13%;
  }
  .cell-applicationStatus {
    width: 14%;
  }
  .cell-fee-status {
    width: 11%;
  }
  .cell-view-application {
    width: 10%;
  }
  .cell-view-doc {
    width: 10%;
  }
  .cell-status {
    width: 8%;
  }
  .cell-action {
    width: 10%;
    .icon-wraper {
      a {
        padding: 0 10px;
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  .add-new-button-block {
    margin-bottom: 30px;
    text-align: end;
  }
}
.table-structure {
  border: 1px solid $accent-gray-color;
  border-radius: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 4px !important;
    height: 4px;
    background: $accent-black-color;
    border-radius: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $accent-black-color !important;
    outline: 0 !important;
    border-radius: 6px !important;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background-color: #f5f5f5;
  }
  .table-wrapper {
    min-width: 1400px;
    .cell {
      padding-right: 10px;
    }
    .table-header {
      .header-row {
        padding: 15px 20px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          left: 15px;
          bottom: 0;
          width: calc(100% - 30px);
          height: 1px;
          background: $accent-gray-color;
        }
        .cell {
          color: $accent-black-color;
          font-weight: $fweight600;
          font-size: $font16;
          line-height: $lheight20;
        }
      }
    }

    .table-body {
      overflow-x: hidden;
      overflow-y: auto;
      .table-body-scroll {
        min-height: 630px;
        max-height: 650px;
        @include lg {
          min-height: 500px;
        }
        .body-row {
          // border-bottom: 1px solid$accent-gray-color;
          position: relative;
          padding: 10px 20px;
          &:after {
            content: "";
            position: absolute;
            left: 15px;
            bottom: 0;
            width: calc(100% - 30px);
            height: 1px;
            background: $accent-gray-color;
          }
          &:last-child {
            border-bottom: none;
          }
          &:active,
          &:hover {
            &:before {
              opacity: 1;
            }
            .cell {
              color: $accent-black-color;
            }
          }
          &:before {
            content: "";
            position: absolute;
            background: $accent-light-gray-color;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -9;
            border-radius: 10px;
            opacity: 0;
            transition: 300ms ease-in-out;
          }
          .cell {
            color: $accent-gray-color;
            font-weight: $fweight400;
            font-size: $font14;
            line-height: $lheight16;
            transition: 300ms ease-in-out;
          }
        }
      }
    }
  }
}

.status-pill {
  max-width: max-content;
  border-radius: 50px;
  padding: 5px 10px;
  text-align: center;
  &.paid-pill {
    background: #f1fff3;
    color: #1f902a;
  }
  &.set-pill {
    background: #f4efff;
    color: #5200ff;
  }
  &.pending-pill {
    color: #7295c6;
    background-color: #f3f8ff !important;
  }
  //   &.set-status-pill {
  //     color: #ff7e7e;
  //     background-color: #fff1f1 !important;
  //   }
}
