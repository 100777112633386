.footer-wrapper {
    background: url("../../images/common/footer-bg-image.png") no-repeat;
    background-size: cover;
    height: 450px;
    @include md {
        height: 360px;
    }
    .footer-section {
        padding-top: 20px;
        .footer-icon-block {
            .logo-icon {
                .img-fluid {
                    @include xl {
                        height: 100px;
                    }
                    @include lg {
                        height: 85px;
                        width: 85px;
                    }
                }
            }
        }
        .footer-content {
            .footer-description-block {
                max-width: 80%;
                margin: auto;
                position: relative;
                padding: 110px 220px 0 450px;
                @include laptop {
                    max-width: 90%;
                }
                @include laptop-min{
                    padding: 110px 220px 0 400px;
                }
                @include xl {
                    padding-left: 350px;
                    padding-top: 100px;
                }
                @include lg {
                    padding: 80px 170px 0 270px;
                }
                @include md {
                    padding: 0;
                    margin-top: 30px;
                }
                &::before {
                    position: absolute;
                    content: "";
                    background: url("../../images/common/footer-maps-img.png") no-repeat;
                    width: 100%;
                    height: 290px;
                    top: 0;
                    top: 0;
                    left: 0;
                    background-size: 100% 100%;
                    @include xl {
                        background-size: 100% 80%;
                        top: 15px;
                    }
                    @include lg{
                        background-size: 100% 65%; 
                    }
                    @include md {
                        content: none;
                    }
                }
                .footer-text-heading {
                    color: $red-primary-color !important;
                    @include md {
                        text-align: center;
                        padding-top: 15px;
                    }
                    span {
                        font-family: "Segeo Ui-semi-bold" !important;
                        font-size: $font18 !important;
                        line-height: $lheight22 !important;
                        @include laptop {
                            font-size: $font16 !important;
                            line-height: $lheight20 !important;
                        }
                        // @include lg {
                        //     font-size: $font14 !important;
                        //     line-height: $lheight18 !important;
                        // }
                    }
                }
                .footer-text-description {
                    padding-top: 30px;
                    color: $accent-white-color;
                    font-size: $font16;
                    cursor: pointer;
                    a:hover {
                        text-decoration: none;
                    }
                    @include laptop {
                        font-size: $font14;
                        line-height: $lheight18;
                    }
                    @include md {
                        text-align: center;
                        padding-top: 10px;
                        font-size: $font12 !important;
                        line-height: $lheight16 !important;
                    }
                }
                .footer-social-media-icon {
                    padding-top: 30px;
                    @include md {
                        justify-content: center;
                        padding-top: 15px;
                    }
                    .social-media-icons {
                        cursor: pointer;
                        img {
                            @include laptop {
                                height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.post-footer-section {
    background-color: $accent-black-color;
    padding: 10px;
    color: $accent-white-color;
    @media (max-width: breakpoint(sm-end)) {
        padding: 10px 20px;
    }
    .post-footer-text {
        .text-post-footer {
            @include md {
                font-size: $font12 !important;
                line-height: $lheight16 !important;
            }
        }
    }
}
