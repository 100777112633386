.header-main-panel {
  position: relative;
  background-color: $accent-white-color;
  width: 100%;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  .header-content {
    &.container-padding {
      padding: 15px 100px !important;
      @include laptop {
        padding: 15px 50px !important;
      }
      @include lg {
        padding: 15px 30px !important;
      }
      @include mobile {
        padding: 10px !important;
      }
    }
  }
  .header-menu {
    .login-button {
      .btn {
        width: 90px !important;
        @include sm {
          width: 55px !important;
        }
      }
      @include sm {
        padding-right: 10px !important;
      }
    }
    .get-appoinment-button {
      .btn {
        height: 30px;
        width: 130px !important;
        @include sm {
          width: 113px !important;
        }
      }
    }
    .menu-section {
      @include sm {
        padding-left: 10px !important;
      }
      .menu-block {
        background: url("../../images/svg-icon/menu-icon.svg") no-repeat;
        height: 29px;
        width: 31px;
        cursor: pointer;
      }
    }
    .desktop-button-panel {
      @include mobile {
        display: none !important;
      }
      .btn {
        @include sm {
          font-size: $font12;
        }
      }
    }
  }
  .header-menu-items-section {
    position: absolute;
    right: 100px;
    top: 115px;
    background-color: $accent-white-color;
    z-index: 2;
    @include laptop {
      top: 115px;
      right: 50px;
    }
    @include lg {
      right: 30px;
    }
    @include mobile {
      top: 105px;
      right: 10px;
    }
    .header-menu-upper-img {
      position: absolute;
      background: url(../../images/common/header-menu-upper-img.png) bottom no-repeat;
      height: 80px;
      width: 100%;
      top: 0;
      z-index: -1;
      opacity: 0.3;
      @include sm {
        width: 180px;
        height: 60px;
      }
    }
    .header-menu-lower-img {
      position: absolute;
      background: url(../../images/common/header-menu-lower-img.png) top no-repeat;
      height: 80px;
      width: 100%;
      bottom: 0;
      z-index: -1;
      opacity: 0.3;
      @include sm {
        width: 180px;
        height: 60px;
      }
    }
    .header-items-menu {
      width: 220px;
      height: 450px;
      span {
        font-family: "Segeo Ui-semi-bold" !important;
      }
      @include sm {
        width: 180px;
        height: 370px;
      }
      a {
        text-decoration: none !important;
      }
      .header-item {
        position: relative;
        padding: 30px 0;
        cursor: pointer;

        @include sm {
          padding: 15px 0;
        }
        &.active-menu {
          position: relative;
          &::after {
            opacity: 1;
            bottom: 15px;
            @include sm {
              bottom: 5px !important;
            }
          }
        }
        &::after {
          content: "";
          position: absolute;
          background: url(../../images/svg-icon/active-menu-icon.svg) no-repeat;
          height: 10px;
          width: 44px;
          left: 0;
          right: 0;
          margin: auto;
          bottom: 0px;
          transition: 0.4s;
          opacity: 0;
          @include sm {
            left: 9px;
            bottom: -5px !important;
            background-size: 80%;
          }
        }
        &:hover {
          &::after {
            opacity: 1;
            bottom: 15px;
            @include sm {
              bottom: 5px !important;
            }
          }
          span {
            color: $red-primary-color;
          }
        }
      }
      .mobile-btn {
        display: none;
        @include mobile {
          display: block;
          margin: 0 auto;
          max-width: 150px;
        }
      }
    }
  }
}
.MuiModal-root {
  .MuiBox-root {
    .modal-modal-description {
      text-align: center;
    }
  }
}
