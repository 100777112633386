.stepper-form-block {
  background: url("../../images/newcontact/contact-bg.png") no-repeat;
  background-size: cover;
  background-position: right;
  .MuiInputLabel-outlined {
    transform: translate(14px, 6px) scale(1) !important;
    &.MuiInputLabel-shrink {
      transform: translate(14px, -8px) scale(0.75) !important;
    }
  }
  .MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
  }
  .MuiStepper-horizontal {
    @include md {
      overflow: auto;
      white-space: nowrap;
      &::-webkit-scrollbar {
        width: 4px !important;
        height: 4px;
        background: $red-primary-color;
        border-radius: 6px !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $red-primary-color !important;
        outline: 0 !important;
        border-radius: 6px !important;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 6px;
        background-color: #f5f5f5;
      }
      .MuiStep-horizontal {
        padding-bottom: 20px;
      }
      .MuiStepConnector-root {
        padding-bottom: 20px;
        .MuiStepConnector-line {
          width: 15px;
        }
      }
    }
  }
  .container-padding {
    padding: 50px 100px !important;
    overflow: hidden;
    @include laptop {
      padding: 50px !important;
    }
    @include lg {
      padding: 50px 30px !important;
    }
    @include mobile {
      padding: 50px 10px !important;
    }
  }
  .stepper-sction-wraper {
    padding: 70px 0 !important;
    @include laptop {
      padding: 50px 0 !important;
    }
    .MuiFormControl-root {
      width: 100%;
      margin: 0;
    }
    .stepper-section {
      position: relative;
      padding: 80px 70px;
      max-width: 100%;
      width: 100%;
      margin: auto;
      background-color: $accent-white-color;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      @include lg {
        padding: 60px 30px;
      }
      @include sm {
        padding: 50px 20px;
      }
      .stepper-box {
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: fit-content;
        height: 60px;
        background-color: $accent-dark-gray-color;
        border-radius: 8px;
        padding: 0 15px;
        @include sm {
          width: 130px;
          height: 53px;
        }
        .stepper-text {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          color: $accent-white-color;
          font-family: "Segeo Ui-bold" !important;
        }
      }
      .form-details {
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
        @include sm {
          margin-bottom: 20px;
        }
        .Form-details-title {
          margin-bottom: 30px;
          @include sm {
            margin-bottom: 20px;
          }
          .title-text {
            font-size: 16px;
            font-family: "Segeo Ui-semi-bold" !important;
            color: $accent-dark-gray-color;
          }
        }
        .Form-block {
          margin-bottom: 30px;
          @include sm {
            margin-bottom: 20px;
          }
          .MuiFormLabel-root {
            font-family: "Segeo Ui-semi-bold" !important;
            color: $accent-dark-gray-color;
          }
          .radioButton-group {
            display: flex !important;
            flex-direction: row !important;
            .MuiSvgIcon-root {
              fill: $red-primary-color !important;
            }
          }
          .checkbox-blok {
            .MuiSvgIcon-root {
              fill: $red-primary-color !important;
            }
          }
        }
      }
    }
  }
  .document-upload-box {
    max-width: 400px;
    height: 100px;
    border: 1px solid $accent-gray-color;
    border-radius: 4px;
    background: $accent-white-color;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-align: left;
    padding: 0 15px;
    .file-select-input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
      #logoFilePicker {
        height: 100%;
        cursor: pointer;
        width: 100%;
      }
    }
  }
  .label-title {
    font-size: 15px;
    letter-spacing: 0;
    font-family: "Segeo Ui-semi-bold" !important;
    color: $accent-dark-gray-color;
  }
  .label-title-italic {
    font-size: 12px;
    font-style: italic;
    padding-bottom: 8px;
    &.color-grey {
      color: $accent-gray-color;
    }
  }
}
