#about-main-panel {
    background: url("../../images/about/about-right-patterns.png") no-repeat;
    background-position: right;
    .about-introduction-section {
        .intro-description {
            .left-intro-section,
            .right-intro-section {
                .left-image-wrapper {
                    position: relative;
                    @include lg {
                        max-width: 70%;
                        margin: auto;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        right:  -10px;
                        bottom:  -10px;
                        width: calc(100% + 10px);
                        height: 70%;
                        background: #393939;
                        z-index: -1;
                    }
                }
                .right-image-wrapper {
                    position: relative;
                    @include lg {
                        max-width: 70%;
                        margin: auto;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        left:  -10px;
                        top:  -10px;
                        width: calc(100% + 10px);
                        height: 70%;
                        background: #393939;
                        z-index: -1;
                    }
                }
                .image-responsive {
                    height: 750px;
                    object-fit: cover;
                    @include lg {
                        height: 400px;
                    }
                }
            }
            .middle-section {
                padding: 20px 50px;

                @include laptop {
                    padding: 30px;
                }
                @include lg {
                    text-align: center;
                    padding: 20px;
                }
                .who-are-we-heading {
                    @include xl {
                        margin-bottom: 10px !important;
                    }
                }
                .new-horizon-text {
                    @include xl {
                        margin-bottom: 10px !important;
                    }
                    span {
                        @include xl {
                            font-size: $font18 !important;
                            line-height: $lheight22 !important;
                        }
                        @include md {
                            font-size: $font16 !important;
                            line-height: $lheight20 !important;
                        }
                    }
                }
                .intro-description-text {
                    color: $accent-gray-color;
                    text-align: justify;
                    @include xl {
                        margin-bottom: 10px !important;
                    }
                    span {
                        @include xl {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
    .our-story-our-vision-section {
        .our-story-description {
            &.sub-section-padding{
                padding: 50px 0 100px 0 !important;
            }
            .left-content {
                padding: 0 100px;
                @include laptop {
                    padding: 0 30px;
                }
                @include lg {
                    text-align: center;
                }
                .left-description-text {
                    text-align: justify;
                    span {
                        color: $accent-gray-color;
                    }
                }
            }
            .right-content {
                @include lg {
                    margin-top: 30px;
                }
                .right-image-wrapper {
                    max-width: 80%;
                    margin: 0 0 0 auto;
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        left:  -10px;
                        top:  -10px;
                        width: calc(100% + 10px);
                        height: calc(100% + 10px);
                        background: #393939;
                        z-index: -1;
                    }

                    @include lg {
                        max-width: 70%;
                        margin: auto;
                    }
                    img {
                        @include mobile {
                            height: 300px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
    .why-choose-us-section {
        .why-choose-us-description {
            .order-left-resp {
                @include lg {
                    order: 2;
                }
                .why-choose-left-content {
                    .left-image-wrapper {
                        max-width: 80%;
                        position: relative;
                        &:before {
                            content: "";
                            position: absolute;
                            right:  -10px;
                            bottom:  -10px;
                            width: calc(100% + 10px);
                            height: calc(100% + 10px);
                            background: #393939;
                            z-index: -1;
                        }
                        @include lg {
                            max-width: 70%;
                            margin: auto;
                            margin-top: 20px;
                        }
                        img {
                            @include mobile {
                                height: 300px;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
            .order-right-resp {
                @include lg {
                    order: 1;
                }
                .why-choose-right-content {
                    padding: 0 100px;
                    @include laptop {
                        padding: 0 30px;
                    }
                    @include lg {
                        text-align: center;
                    }
                    .right-description-text {
                        text-align: justify;
                    }
                }
            }
        }
    }
    .our-partners-section {
        .our-partners-content {
            &.sub-section-padding {
                padding: 80px 0 !important;
            }
            .our-partner-slider {
                background-color: $accent-white-color;
                padding: 30px;
                @include mobile{
                    padding: 20px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    width: 90%;
                    height: 10px;
                    background-color: $accent-dark-gray-color;
                    right: 0;
                    top: 20px;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 90%;
                    height: 10px;
                    background-color: $accent-dark-gray-color;
                    left: 0;
                    bottom: 20px;
                }
                .partners-university {
                    padding: 30px;
                    .owl-carousel {
                        &.owl-theme {
                            .owl-nav {
                                .owl-prev {
                                    background: url("../../images/svg-icon/left-dark-arrow.svg") no-repeat;
                                    background-size: 100% 100%;
                                    height: 36px;
                                    width: 21px;
                                    position: absolute;
                                    left: -30px;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto 0;
                                    @include lg {
                                        height: 30px;
                                        width: 15px;
                                    }                            
                                }
                                .owl-next {
                                    background: url("../../images/svg-icon/right-dark-arrow.svg") no-repeat;
                                    background-size: 100% 100%;
                                    height: 36px;
                                    width: 21px;
                                    position: absolute;
                                    right: -30px;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto 0;
                                    @include lg {
                                        height: 30px;
                                        width: 15px;
                                    }                      
                                }
                            }
                        }
                    }
                    .partner-card {
                        height: auto;
                        min-width: 415px;
                        width: 100%;
                        @include laptop {
                            min-width: 200px;
                        }
                        @include md {
                            margin: 0 auto;
                        }
                        .image-wrapper {
                            background-color: $accent-light-gray-color;
                            max-width: 90%;
                            margin: auto;
                            padding: 10px;
                            @include laptop {
                                max-width: 85%;
                            }
                        }
                    }
                }
            }
        }
    }
}
