.MuiInputLabel-root {
  font-family: "Segeo Ui" !important;
  font-style: italic !important;
  font-size: $font16 !important;
  line-height: $lheight12 !important;
  color: $accent-form-field-text-color !important;
  overflow: unset !important;
  top: 4px !important;
  @include lg {
    font-size: $font14 !important;
    line-height: $lheight18 !important;
  }
}
.MuiOutlinedInput-notchedOutline {
  border-color: $accent-gray-color !important;
}
input,
textarea {
  font-family: "Segeo Ui" !important;
  color: $accent-gray-color !important ;
  &:hover {
    border-color: $accent-gray-color !important;
  }
}

#home-page-main-wrapper {
  .MuiFormControl-root {
    background-color: $accent-white-color !important;
    border-radius: 8px !important;
  }
  .MuiInputLabel-root {
    @include lg {
      top: 0px !important;
    }
  }
}
#service-main-panel {
  .MuiFormControl-root {
    width: 100%;
    background-color: $accent-white-color !important;
  }
  .MuiOutlinedInput-root {
    padding: 0;
  }
  .MuiButtonBase-root {
    margin-right: 0;
    position: absolute;
    right: 16px;
    height: 24px;
    width: 24px;
    &:hover {
      background-color: transparent !important;
    }
  }
  .MuiSvgIcon-root {
    display: none;
  }
  button:focus {
    outline: none !important;
  }
  input {
    background-color: $accent-white-color !important;
    background-position: 96% !important;
    &:hover {
      border-color: $accent-gray-color !important;
    }
    &:focus {
      border-color: $accent-gray-color !important;
      border-width: 2px !important;
    }
  }
  .name-input {
    input {
      background: url(../../images/svg-icon/user.svg) no-repeat;
    }
  }
  .phone-input {
    input {
      background: url(../../images/svg-icon/telephone.svg) no-repeat;
    }
  }
  .inspection-input {
    input {
      background: url(../../images/svg-icon/calendar.svg) no-repeat;
      background-position: 98% !important;
    }
  }
  .location-input {
    input {
      background: url(../../images/svg-icon/pin.svg) no-repeat;
    }
  }
}
.MuiPaper-root {
  .PrivatePickersYear-yearButton:hover {
    background-color: transparent;
  }
  .MuiTypography-root:hover {
    background-color: transparent;
  }
  .MuiTypography-root:focus {
    background-color: transparent;
  }
  .MuiButtonBase-root:hover {
    background-color: transparent;
  }
  button:focus {
    outline: none !important;
  }
}

#blog-wrapper {
  input {
    background: url(../../images/svg-icon/search-icon.svg) no-repeat;
    padding: 0px 40px 7px !important;
    &:hover {
      border-color: $accent-light-gray-color !important;
    }
  }
  .MuiInput-root:after {
    border-bottom: 2px solid $accent-gray-color !important;
  }
}
#new-contact-wrapper {
  .MuiFormControl-root {
    width: 100%;
    margin: 0;
  }
  .MuiPaper-root .Mui-selected {
    background-color: $accent-gray-color !important;
    outline: transparent;
  }
  .MuiSelect-nativeInput {
    color: $accent-gray-color !important;
  }
  .dropdown-block {
    .MuiOutlinedInput-root {
      background: url("../../images/common/dropdown-arrow.png") no-repeat;
      background-position: 96%;
    }
  }
  .marital-status-input,
  .spouse-education-input {
    .MuiOutlinedInput-root {
      background-position: 92% !important;
      @include sm {
        background-position: 96% !important;
      }
    }
  }
  .MuiSvgIcon-root {
    display: none;
    &.MuiSelect-iconOpen {
      transform: rotate(45deg) !important;
    }
  }
  .MuiSelect-select {
    font-family: "Segeo Ui" !important;
    color: $accent-gray-color !important ;
  }
}
.MuiMenuItem-root {
  font-family: "Segeo Ui" !important;
  font-style: italic !important;
  color: $accent-gray-color !important ;
}
.MuiMenuItem-root.Mui-selected {
  background-color: $accent-light-gray-color !important;
}
.MuiDialogContent-dividers {
  padding: 10px 24px 20px !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}
.MuiDialogTitle-root {
  padding: 10px 10px 0 !important;
}

.MuiStepper-root {
  .MuiSvgIcon-root {
    color: $accent-dark-gray-color !important;
    &.Mui-active {
      color: $red-primary-color !important;
    }
    .MuiStepIcon-text {
      font-family: "Segeo Ui-bold" !important;
    }
  }
  .MuiStepLabel-label {
    color: $accent-gray-color !important;
    font-family: "Segeo Ui" !important;
    &.Mui-active {
      color: $accent-black-color !important;
    }
  }
}
.MuiStack-root {
  padding: 0 !important;
}

.MuiTooltip-tooltip {
  background-color: $accent-white-color !important;
  border: 1px solid $red-primary-color !important;
  color: $red-primary-color !important;
  position: relative !important;
  padding: 0 5px !important;
  height: 20px !important;
  line-height: 18px !important;
  margin-top: 6px !important;
  font-size: $font10 !important;
  .MuiTooltip-arrow {
    width: 12px;
    height: 11px;
    &::before {
      position: absolute !important;
      content: "";
      left: 0 !important;
      right: 0 !important;
      margin: auto !important;
      top: -1px !important;
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      border-bottom: 6px solid $accent-white-color !important;
      z-index: 999 !important;
      background-color: transparent !important;
      transform: rotate(0) !important;
    }
    &::after {
      position: absolute !important;
      content: "";
      left: 0 !important;
      right: 0 !important;
      margin: auto !important;
      top: 3px !important;
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      border-bottom: 6px solid $red-primary-color !important;
      z-index: 99 !important;
      background-color: transparent !important;
    }
  }
}
