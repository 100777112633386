.loadingImage{
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
    .loader{
        display: block;
        margin: 0 auto;
        background: url(../../images/common/loading.gif) no-repeat;
        background-size: contain;
        width: 200px;
        height: 200px;
    }
}