#blog-wrapper {
    background: url("../../images/blog/blog-bg.png") no-repeat;
    background-size: cover;
    .blog-content {
        .respo-gutters{
            @include sm{
                margin-left: 0;
                margin-right: 0
            }
        }
        .left-content {
            .heading {
                .heading-text {
                    font-size: $font22;
                    line-height: $lheight26;
                    @include xl {
                        font-size: $font20 !important;
                        line-height: $lheight24 !important;
                    }
                }
                .red-text {
                    font-size: $font22;
                    line-height: $lheight26;
                    font-family: "Segeo Ui-bold" !important;
                    color: $red-primary-color;
                    @include xl {
                        font-size: $font20 !important;
                        line-height: $lheight24 !important;
                    }
                }
            }
            .search-blog,
            .recent-posts,
            .categories {
                max-width: 100%;
                padding: 30px 40px;
                background-color: $accent-light-gray-color;
                border-radius: 10px;
                height: auto;
                @include md {
                    padding: 20px 20px;
                }
            }
            .search-blog {
                height: 180px;
                @include lg {
                    height: 160px;
                }
                @include md {
                    height: 140px;
                }
                .search-area {
                    margin-top: 30px;
                    padding: 7px 0px;
                }
            }
            .recent-posts {
                margin-top: 20px;
                .recent-post-list {
                    // @include lg {
                    //     max-height: 400px;
                    //     overflow-y: auto;
                    // }
                    .posts-heading-text {
                        .posts-text {
                            border-bottom: 1px solid $accent-border-bottom-color;
                            padding: 25px 0;
                            .text-description {
                                cursor: pointer;
                                &:hover {
                                    color: $red-primary-color;
                                }
                            }
                        }
                    }
                }
            }
            .categories {
                margin-top: 20px;
                .categories-tag-list {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-top: 30px;
                    @include xl {
                        max-height: 150px;
                        overflow-y: auto;
                    }
                    .categories-tag-item {
                        max-width: 100%;
                        height: 40px;
                        margin: 0 15px 15px 0;
                        a {
                            text-decoration: none;
                            color: $accent-dark-gray-color;
                            padding: 8px;
                            border-radius: 5px;
                            border: 1px solid $accent-border-bottom-color;
                            background-color: #e4e4e4;
                            display: block;
                            @include md {
                                font-size: $font14;
                                line-height: $lheight18;
                            }
                            @include sm {
                                font-size: $font12;
                                line-height: $lheight16;
                            }
                        }
                    }
                }
            }
        }
        .right-content {
            @include xl {
                max-height: 950px;
                overflow-y: auto;
                overflow-x: hidden;
            }
            .blog-card {
                max-height: 560px;
                height: 100%;
                .blog-img {
                    img {
                        border-radius: 20px 20px 0 0;
                        width: 100%;
                        height: 100%;
                    }
                }
                .blog-body {
                    text-align: center;
                    background-color: $accent-light-gray-color;
                    padding: 20px 60px 60px;
                    border-radius: 0 0 20px 20px;
                    @include laptop-max {
                        padding: 20px 50px 50px;
                    }
                    @include laptop {
                        padding: 20px 40px 40px;
                    }
                    .blog-body-text {
                        @include laptop-max {
                            font-size: $font14 !important;
                            line-height: $lheight18 !important;
                        }
                    }
                    .blog-heading {
                        height: 50px;
                        .blog-title {
                            font-family: "Segeo Ui-semi-bold" !important;
                            font-size: $font18;
                            line-height: $lheight30;
                            @include laptop {
                                font-size: $font16 !important;
                                line-height: $lheight20 !important;
                            }
                            @include sm {
                                font-size: $font14 !important;
                                line-height: $lheight18 !important;
                            }
                        }
                    }
                }
                .blog-footer {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    margin-top: -16px;
                    .submit-now {
                        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
                        width: 180px;
                        height: 30px;
                        background-color: $red-primary-color;
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }
}
