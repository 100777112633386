.mb-0 {
    margin-bottom: 0px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.ml-10 {
    margin-left: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px;
}

.ml-15 {
    margin-left: 15px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mr-25 {
    margin-right: 25px;
}

.ml-25 {
    margin-left: 25px;
}

.mr-30 {
    margin-right: 30px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.ml-35 {
    margin-left: 35px;
}

.mr-35 {
    margin-right: 35px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mt-35 {
    margin-top: 35px;
}

.mr-40 {
    margin-right: 40px;
}

.ml-40 {
    margin-left: 40px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.ml-50 {
    margin-left: 50px;
}

.mr-50 {
    margin-right: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mr-60 {
    margin-right: 60px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mb-60 {
    margin-bottom: 60px;
}

.ml-60 {
    margin-left: 60px;
}
.mt-80{
    margin-top: 80px;
}
.mt-100 {
    margin-top: 100px !important;
}

.ml-auto{
    margin-left: auto !important;
}

.mr-auto{
    margin-right: auto !important;
}

.m-auto{
    margin: 0 auto !important;
}