.btn {
    cursor: pointer;
    text-align: center;
    height: 30px;
    width: 180px;
    border-radius: 0;
    border: 1px solid $accent-gray-color;
    padding: 0;
    border: 1px solid transparent;
    &:focus {
        box-shadow: none !important;
    }
    @include md {
        font-size: $font14;
        line-height: $lheight20;
    }
    &.btn-secondary {
        background-color: $accent-dark-gray-color !important;
        color: $accent-white-color !important;
        &:hover {
            border: 1px solid $accent-gray-color !important;
            background-color: $accent-white-color !important;
            color: $accent-dark-gray-color !important;
        }
        &:focus {
            border-color: $accent-gray-color !important;
        }
    }
    &.btn-medium {
        font-size: $font16;
        line-height: $lheight20;
        width: 180px;
        height: 30px;
        @include md {
            width: 150px;
            font-size: $font14;
        }
    }
    &.btn-primary {
        background-color: $red-primary-color !important;
        color: $accent-white-color !important;
        &:hover {
            border: 1px solid $red-primary-color !important;
            background-color: $accent-white-color !important;
            color: $red-primary-color !important;
        }
        &:focus {
            border-color: $red-primary-color !important;
        }
    }
    &.btn-white {
        background-color: $accent-white-color !important;
        color: $red-primary-color !important;
        border: 1px solid transparent !important;
        &:hover {
            border: 1px solid $accent-white-color !important;
            background-color: $red-primary-color !important;
            color: $accent-white-color !important;
        }
    }
}
