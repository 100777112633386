.pd-0 {
    padding: 0px !important;
}
.pd-10{
    padding: 10px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pd-5 {
    padding: 5px;
}

.pl-0 {
    padding-left: 0px;
}
.pr-0 {
    padding-right: 0px;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pr-10 {
    padding-right: 10px;
}

.pl-10 {
    padding-left: 10px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pd-15 {
    padding: 0 15px !important;
}

.pr-20 {
    padding-right: 20px;
}

.pl-20 {
    padding-left: 20px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pb-20 {
    padding-bottom: 20px;
}

.pl-25 {
    padding-left: 25px;
}

.pr-25 {
    padding-right: 25px;
}

.pr-30 {
    padding-right: 30px;
}

.pl-30 {
    padding-left: 30px;
}

.pt-30 {
    padding-top: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pr-35 {
    padding-right: 35px;
}

.pr-40 {
    padding-right: 40px;
}

.pl-40 {
    padding-left: 40px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-60 {
    padding-top: 60px;
}
.pb-40 {
    padding-bottom: 40px;
}

.pl-70 {
    padding-left: 70px;
}

.pb-100 {
    padding-bottom: 100px !important;
}