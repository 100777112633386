//font variables
/*theme one font variable*/
$font-stack: "NunitoSans-Regular", sans-serif;

//font size variables
$font70: 70px;
$font52: 52px;
$font50: 50px;
$font48: 48px;
$font40: 40px;
$font32: 32px;
$font30: 30px;
$font35: 35px;
$font26: 26px;
$font25: 25px;
$font22: 22px;
$font20: 20px;
$font18: 18px;
$font16: 16px;
$font14: 14px;
$font12: 12px;
$font10: 10px;

//line height variables

$lheight74: 74px;
$lheight56: 56px;
$lheight54: 54px;
$lheight52: 52px;
$lheight44: 44px;
$lheight39: 39px;
$lheight36: 36px;
$lheight34: 34px;
$lheight30: 30px;
$lheight28: 28px;
$lheight26: 26px;
$lheight24: 24px;
$lheight22: 22px;
$lheight20: 20px;
$lheight18: 18px;
$lheight16: 16px;
$lheight14: 14px;
$lheight12: 12px;

//font weight variables
$fweight300: 300;
$fweight400: 400;
$fweight500: 500;
$fweight600: 600;
$fweight700: 700;
$fweight900: 900;

//media query breakpoints
$screen-desktop: 1920px;
$screen-laptop-max: 1680px;
$screen-laptop-min: 1400px;
$screen-laptop: 1440px;
$screen-xl-max: 1199px;
$screen-xl-min: 1024px;
$screen-lg-min: 992px;
$screen-lg-max: 991px;
$screen-md-max: 767px;
$screen-sm-max: 575px;
$screen-sm-min: 480px;
$screen-mobile-max: 375px;
$screen-mobile-min: 320px;

// Font-colors

$accent-white-color: #ffff;
$accent-black-color:#0000;
$red-primary-color: #ED1C24;
$dark-red-color: #97050B;
$accent-gray-color:#808285;
$accent-black-color:#1E1E2A;
$accent-dark-gray-color:#393939;
$accent-light-gray-color:#F6F6F6;
$accent-form-field-text-color:#afafaf;
$accent-border-bottom-color:#d1d1d1;

