.banner-main-panel {
    position: relative;
    .banner-img {
        height: 650px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background: $accent-black-color;
            opacity: 60%;
        }
        @include sm {
            height: 500px;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .banner-description {
        bottom: 100px;
        position: absolute;
        margin: auto;
        left: 100px;
        max-width: 930px;
        @include lg {
            text-align: center;
            left: 15px;
            right: 15px;
            margin: auto;
        }
        .banner-heading {
            @include lg {
                font-size: $font32 !important;
                line-height: $lheight36 !important;
            }
        }
    }
}
.banner-carousel {
    &.owl-carousel {
        &.owl-theme {
            .owl-dots {
                position: absolute;
                bottom: 20px;
                left: 0;
                right: 0;
                margin: 0 auto;
                .owl-dot {
                    vertical-align: middle;
                    &.active {
                        span {
                            background-color: $accent-white-color;
                            height: 24px;
                            width: 24px;
                            @include lg {
                                height: 14px;
                                width: 14px;
                            }
                        }
                    }
                    span {
                        background-color: rgba($color: #ffff, $alpha: 0.4);
                        width: 20px;
                        height: 20px;
                        @include lg {
                            height: 10px;
                            width: 10px;
                        }
                    }
                }
            }
        }
        .banner-main-panel {
            position: relative;
            .banner-image {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background: $accent-black-color;
                    opacity: 60%;
                    z-index: 9;
                }
                .banner-img {
                    height: 650px;
                    @include sm {
                        height: 500px;
                    }
                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .banner-description {
                    bottom: 100px;
                    position: absolute;
                    margin: auto;
                    left: 100px;
                    max-width: 930px;
                    z-index: 9;
                    @include lg {
                        text-align: center;
                        left: 15px;
                        right: 15px;
                        margin: auto;
                    }
                    .banner-heading {
                        @include lg {
                            font-size: $font32 !important;
                            line-height: $lheight36 !important;
                        }
                        @include sm {
                            font-size: $font20 !important;
                            line-height: $lheight24 !important;
                        }
                    }
                }
            }
        }
    }
}
