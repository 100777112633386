@font-face {
    font-family: "Segeo Ui";
    src: url("../../fonts/segoeui.ttf");
}
@font-face {
    font-family: "Segeo Ui-bold";
    src: url("../../fonts/segoeuib.ttf");
}
@font-face {
    font-family: "Segeo Ui-semi-bold";
    src: url("../../fonts/seguisb.ttf");
}

// Font-size

.font-16 {
    font-size: $font16;
    line-height: $lheight20;
}
.font-18 {
    font-size: $font18;
    line-height: $lheight22;
}
.font-20 {
    font-size: $font20;
    line-height: $lheight24;
}
.font-22 {
    font-size: $font22;
    line-height: $lheight26;
}
.font-26 {
    font-size: $font26;
    line-height: $lheight30;
}
.font-32 {
    font-size: $font32;
    line-height: $lheight36;
}
.font-35 {
    font-size: $font35;
    line-height: $lheight39;
}
.font-40 {
    font-size: $font40;
    line-height: $lheight44;
}
.font-48 {
    font-size: $font48;
    line-height: $lheight52;
}
.font-50 {
    font-size: $font50;
    line-height: $lheight54;
}
.font-52 {
    font-size: $font52;
    line-height: $lheight56;
}
.font-70 {
    font-size: $font70;
    line-height: $lheight74;
}

// font-weights

.font-700 {
    font-family: "Segeo Ui-bold" !important;
}
.font-600 {
    font-family: "Segeo Ui-semi-bold" !important;
}

.text-white {
    color: $accent-white-color;
}
.text-primary-color {
    color: $red-primary-color;
}
.dark-red-color {
    color: $dark-red-color;
}
.gray-color {
    color: $accent-gray-color;
}
.black-color {
    color: $accent-black-color;
}
.dark-gray-color{
    color: $accent-dark-gray-color;
}
.text-center{
    text-align: center !important;
}
.text-italic {
    font-style: italic !important;
}
.position-relative {
    position: relative !important;
}
span {
    font-family: "Segeo Ui" !important;
    color: $accent-gray-color;
    @include lg {
        font-size: $font14 !important;
        line-height: $lheight16 !important;
    }
    @include mobile {
        font-size: $font12 !important;
        line-height: $lheight14 !important;
    }
}

h2 {
    font-size: $font32;
    line-height: $lheight36;
    font-family: "Segeo Ui-semi-bold" !important;
    @include xl {
        font-size: $font26 !important;
        line-height: $lheight30 !important;
    }
    @include md {
        font-size: $font20 !important;
        line-height: $lheight24 !important;
    }
}

p {
    font-size: $font16;
    line-height: $lheight30;
    @include xl {
        font-size: $font14 !important;
        line-height: $lheight28 !important;
    }
    @include md {
        font-size: $font12 !important;
        line-height: $lheight18 !important;
    }
}
