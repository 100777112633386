#home-page-main-wrapper {
    .home-page-content {
        .our-services-wrapper {
            background: url("../../images/home/our-service-bg-img.png") no-repeat;
            background-position: right bottom;
            .our-services-left-content {
                @include xl {
                    text-align: center;
                    padding-top: 20px;
                }
                .our-services-description {
                    max-width: 534px;
                    @include xl {
                        max-width: 100%;
                    }
                }
            }
            .our-services-right-content {
                .flex-row-wrap {
                    .image-card {
                        max-width: 230px;
                        cursor: pointer;
                        .image-wrapper {
                            margin: auto;
                            position: relative;
                            @include md {
                                margin-top: 20px;
                            }
                            &::before {
                                position: absolute;
                                content: "";
                                background: url("../../images/common/image-red-hover.png") no-repeat;
                                background-size: 100% 100%;
                                height: 100%;
                                max-height: 100%;
                                max-width: 100%;
                                width: 230px;
                                left: 0;
                                right: 0;
                                margin: auto;
                                top: 0;
                                z-index: 9;
                                opacity: 0;
                                @include xl {
                                    left: -2px;
                                }
                            }
                            &:hover {
                                &:before {
                                    opacity: 1;
                                }
                            }
                            .img-resp {
                                height: auto;
                                width: 100%;
                            }
                            .image-text {
                                @include md {
                                    font-size: $font16;
                                    line-height: $lheight20;
                                }
                            }
                        }
                        @include xl {
                            max-width: 70%;
                            margin: auto;
                        }
                    }
                    .owl-carousel {
                        &.owl-theme {
                            .owl-nav {
                                .owl-prev {
                                    @include xl {
                                        background: url("../../images/svg-icon/left-dark-arrow.svg") no-repeat;
                                        background-size: 100%;
                                        background-position: left;
                                        height: 36px;
                                        width: 21px;
                                        position: absolute;
                                        left: -30px;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto 0;
                                    }
                                    @include lg {
                                        left: -10px;
                                        background-size: 80%;
                                    }
                                    @include sm {
                                        background-size: 60%;
                                    }
                                    @include mobile {
                                        left: 0;
                                    }
                                }
                                .owl-next {
                                    @include xl {
                                        background: url("../../images/svg-icon/right-dark-arrow.svg") no-repeat;
                                        background-size: 100%;
                                        background-position: right;
                                        height: 36px;
                                        width: 21px;
                                        position: absolute;
                                        right: -30px;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto 0;
                                    }
                                    @include lg {
                                        right: -10px;
                                        background-size: 80%;
                                    }
                                    @include sm {
                                        background-size: 60%;
                                    }
                                    @include mobile {
                                        right: 0;
                                    }
                                }
                            }
                        }
                    }
                    @include xl {
                        padding-top: 30px;
                    }
                }
            }
        }
        // .student-testimonial-wrapper {
        //     background: url("../../images/home/student-testimonial-bg-img.png") no-repeat;
        //     height: 700px;
        //     background-size: cover;
        //     .student-testimonial-content {
        //         .student-testimonial-heading {
        //             max-width: 627px;
        //             padding-right: 20px;
        //             @include lg {
        //                 max-width: 100%;
        //                 text-align: center;
        //                 padding-right: 0;
        //             }
        //             .student-title {
        //                 span {
        //                     @include sm {
        //                         font-size: $font20 !important;
        //                         line-height: $lheight24;
        //                     }
        //                 }
        //             }
        //             .student-subtitle {
        //                 span {
        //                     @include sm {
        //                         font-size: $font14 !important;
        //                         line-height: $lheight20;
        //                     }
        //                 }
        //             }
        //             .student-description {
        //                 span {
        //                     @include sm {
        //                         font-size: $font12 !important;
        //                         line-height: $lheight16;
        //                     }
        //                 }
        //             }
        //         }
        //         .student-testimonial-description {
        //             height: auto;
        //             width: 600px;
        //             max-width: 100%;
        //             background-color: $accent-white-color;
        //             border-radius: 20px;
        //             padding: 95px 70px;
        //             @include lg {
        //                 margin: auto;
        //             }
        //             @include md {
        //                 height: 324px;
        //             }
        //             @include sm {
        //                 padding: 70px 40px;
        //             }
        //             &::before {
        //                 content: "";
        //                 position: absolute;
        //                 background: url(../../images/svg-icon/left-card-icon.svg) no-repeat;
        //                 background-size: 100% 100%;
        //                 height: 68px;
        //                 width: 174px;
        //                 z-index: 1;
        //                 left: 0;
        //                 top: 0;

        //                 @include md {
        //                     height: 49px;
        //                     width: 125px;
        //                 }
        //             }
        //             &::after {
        //                 content: "";
        //                 position: absolute;
        //                 background: url(../../images/svg-icon/right-card-icon.svg) no-repeat;
        //                 background-size: 100% 100%;
        //                 height: 68px;
        //                 width: 174px;
        //                 z-index: 1;
        //                 right: 0;
        //                 bottom: 0;

        //                 @include md {
        //                     height: 49px;
        //                     width: 125px;
        //                 }
        //             }
        //             .student-card-text {
        //                 &::after {
        //                     content: "";
        //                     position: absolute;
        //                     background: url(../../images/svg-icon/right-quotes-symbol.svg) no-repeat;
        //                     background-size: 100% 100%;
        //                     height: 65px;
        //                     width: 75px;
        //                     right: 0;
        //                     bottom: -75px;
        //                     @include laptop {
        //                         height: 50px;
        //                         width: 50px;
        //                     }
        //                     @include lg {
        //                         bottom: -90px;
        //                         right: -30px;
        //                     }
        //                     @include md {
        //                         bottom: -74px;
        //                     }
        //                     @include sm {
        //                         content: none;
        //                     }
        //                 }
        //                 max-width: 430px;
        //                 .student-info {
        //                     @include sm {
        //                         font-size: $font14;
        //                         line-height: $lheight18;
        //                     }
        //                 }
        //             }
        //             .student-name {
        //                 .font-18 {
        //                     @include sm {
        //                         font-size: $font12;
        //                         line-height: $lheight16;
        //                     }
        //                 }
        //                 .university-name {
        //                     @include sm {
        //                         font-size: $font12 !important;
        //                         line-height: $lheight16 !important;
        //                     }
        //                 }
        //                 .left-arrow-content {
        //                     background: url(../../images/svg-icon/left-white-arrow.svg) no-repeat;
        //                     background-size: contain;
        //                     height: 25px;
        //                     width: 25px;
        //                     left: -20px;
        //                     top: 0;
        //                     bottom: 0;
        //                     margin: auto 0;
        //                 }
        //                 .right-arrow-content {
        //                     background: url(../../images/svg-icon/right-white-arrow.svg) no-repeat;
        //                     background-size: contain;
        //                     height: 25px;
        //                     width: 25px;
        //                     right: -30px;
        //                     top: 0;
        //                     bottom: 0;
        //                     margin: auto 0;
        //                 }
        //             }
        //             .dot-symbol-image {
        //                 right: 40px;
        //                 top: 210px;
        //                 @include xl {
        //                     right: 40px;
        //                     top: 210px;
        //                 }
        //                 @include md {
        //                     right: 20px;
        //                     top: 210px;
        //                 }
        //                 img {
        //                     @include laptop {
        //                         height: 50px;
        //                         width: 50px;
        //                     }
        //                     @include sm {
        //                         display: none;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        // .our-success-wrapper {
        //     background: url("../../images/home/our-success-bg-image.png") no-repeat;
        //     background-size: cover;
        //     background-position: center center;
        //     position: relative;
        //     &.container-padding {
        //         padding: 100px !important;
        //         @include laptop-max {
        //             padding: 50px !important;
        //         }
        //         @include md {
        //             padding: 30px !important;
        //         }
        //         @include mobile {
        //             padding: 10px !important;
        //         }
        //     }
        //     .map-pattern-block {
        //         position: absolute;
        //         right: 0;
        //         top: 0;
        //         max-width: 46%;
        //         @include laptop-max {
        //             max-width: 50%;
        //         }
        //         @include laptop {
        //             max-width: 55%;
        //         }
        //         @include xl {
        //             max-width: 65%;
        //         }
        //         @include lg {
        //             max-width: 79%;
        //         }
        //         @include md {
        //             max-width: 96%;
        //         }
        //         @include sm {
        //             display: none;
        //         }
        //     }
        //     .our-success-content {
        //         padding-bottom: 200px;
        //         @include xl {
        //             padding-bottom: 50px;
        //         }
        //         .order {
        //             @include xl {
        //                 order: 2;
        //             }
        //         }
        //         .our-success-section {
        //             @include xl {
        //                 padding-top: 250px;
        //             }
        //             @include md {
        //                 padding-top: 175px;
        //             }
        //             @include sm {
        //                 padding-top: 70px;
        //             }
        //             .heading-class {
        //                 @include md {
        //                     margin-top: 80px;
        //                 }
        //                 @include sm {
        //                     margin-top: 0;
        //                     text-align: center;
        //                 }
        //             }
        //             .section-description {
        //                 .block-image {
        //                     .resp-image {
        //                         @include xl {
        //                             height: 80px;
        //                         }

        //                         @include lg {
        //                             height: 60px;
        //                         }
        //                     }
        //                 }
        //                 .description-block {
        //                     margin-top: 50px;
        //                     max-width: 125px;
        //                     text-align: center;
        //                     @include md {
        //                         max-width: 93px;
        //                     }
        //                     @include sm {
        //                         max-width: 250px;
        //                     }
        //                 }
        //                 .numbers {
        //                     span {
        //                         @include xl {
        //                             font-size: $font26 !important;
        //                             line-height: $lheight30 !important;
        //                         }
        //                         @include lg {
        //                             font-size: $font20 !important;
        //                             line-height: $lheight24 !important;
        //                         }
        //                     }
        //                 }
        //                 .name {
        //                     span {
        //                         @include xl {
        //                             font-size: $font18 !important;
        //                             line-height: $lheight24 !important;
        //                         }
        //                         @include lg {
        //                             font-size: $font16 !important;
        //                             line-height: $lheight18 !important;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     .looking-for-visa-section {
        //         .looking-for-visa-block {
        //             padding-left: 400px;
        //             @include laptop-max {
        //                 padding-left: 330px;
        //             }
        //             @include laptop {
        //                 padding-left: 230px;
        //             }
        //             @include xl {
        //                 padding-left: 0;
        //                 max-width: 45%;
        //                 margin-left: auto;
        //             }
        //             @include lg {
        //                 max-width: 56%;
        //             }
        //             @include md {
        //                 max-width: 68%;
        //             }
        //             @include sm {
        //                 margin: auto;
        //                 max-width: 100%;
        //             }
        //             .section-heading {
        //                 @include sm {
        //                     text-align: center;
        //                 }
        //             }
        //             .form-field {
        //                 padding-top: 20px;
        //                 @include laptop {
        //                     padding-top: 10px;
        //                 }
        //                 @include sm {
        //                     display: flex;
        //                     align-items: center;
        //                     justify-content: center;
        //                     flex-direction: column;
        //                 }
        //                 .name-input,
        //                 .email-input,
        //                 .contact-number-input,
        //                 .message-input {
        //                     width: 450px !important;
        //                     margin-top: 10px !important;
        //                     @include laptop-max {
        //                         width: 380px !important;
        //                     }
        //                     @include lg {
        //                         width: 330px !important;
        //                     }
        //                     @include sm {
        //                         max-width: 475px !important;
        //                         width: 100% !important;
        //                     }
        //                 }
        //                 .button-wrapper {
        //                     @include sm {
        //                         text-align: center;
        //                     }
        //                     .submit-now {
        //                         width: 180px;
        //                         background-color: $red-primary-color;
        //                         position: relative;
        //                         text-align: left;
        //                         padding: 0 20px;
        //                         margin-top: 60px;
        //                         transition: 500ms ease-in-out;
        //                         @include laptop {
        //                             margin-top: 30px;
        //                         }
        //                         @include xl {
        //                             width: 150px;
        //                         }
        //                         @include lg {
        //                             padding: 0 10px;
        //                             width: 140px;
        //                         }
        //                         &::after {
        //                             position: absolute;
        //                             content: "";
        //                             background: url("../../images/common/right-arrow.png") no-repeat;
        //                             height: 11px;
        //                             width: 19px;
        //                             right: 15px;
        //                             top: 0;
        //                             bottom: 0;
        //                             margin: auto;
        //                         }
        //                         &:hover {
        //                             &::after {
        //                                 background: url("../../images/svg-icon/red-right-arrow.svg") no-repeat;
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        .inquiry-form-wrapper {
            background: url("../../images/home/inquiry-form-bg.png");
            background-size: cover;
            // background-position: center center;
            // height: 400px;
            &.container-padding{
                padding: 130px 100px !important;
                @include lg {
                    padding: 80px 30px!important;
                }
            }
            .inquiry-form-section {
                .inquiry-text-block{
                    max-width: 550px;
                    margin: auto;
                    width: 100%;
                    @include md{
                        max-width: 400px;
                    }
                    .inquiry-text{
                        line-height: $lheight44;
                    }
                }
                .button-wrapper {
                    @include sm {
                        text-align: center;
                    }
                    .submit-now {
                        width: 140px;
                        background-color: $red-primary-color;
                        position: relative;
                        text-align: left;
                        padding: 0 10px;
                        transition: 500ms ease-in-out;
                        @include md{
                            width: 120px;
                        }
                        &::after {
                            position: absolute;
                            content: "";
                            background: url("../../images/common/right-arrow.png") no-repeat;
                            height: 11px;
                            width: 19px;
                            right: 10px;
                            top: 2px;
                            bottom: 0;
                            margin: auto;
                        }
                        &:hover {
                            &::after {
                                background: url("../../images/svg-icon/red-right-arrow.svg") no-repeat;
                            }
                        }
                    }
                }
            }
        }
    }
}
